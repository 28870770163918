<template>
  <div class="player-props-root">
    <div
      v-if="data.length"
      class="single-player-props-container"
    >
      <div
        v-for="player in data"
        :key="player.playerIndex"
        class="single-player-props"
      >
        <div class="player-props-header">
          <p class="player-name">
            <span>{{ formatPlayerName(player.playerName) }}</span>
            <Tooltip
              v-if="player.star"
              text="Star player"
            >
              <Icon
                name="star"
              />
            </Tooltip>
            <span
              v-if="player.suspendedReasons"
              class="dot-space"
            >&bull;</span>
            <Tooltip
              v-if="player.suspendedReasons"
              :text="'Incorect projected minutes'"
              right
            >
              <Icon
                :name="'alert-triangle'"
                :class="['incorect-projected-minutes-icon']"
              />
            </Tooltip>
          </p>
        </div>
        <PlayerMinutesRemainingInfo
          v-if="showMinutesRemainingInfo"
          :player-minutes-remaining-value="playerMinutesRemainingValue(player)"
          :simple-view="true"
        />
        <div class="player-params-container">
          <div
            v-for="param in playerParamsBySport"
            :key="param.param"
            class="player-params-single"
          >
            <div class="params-single-header">
              {{ param.label }}
            </div>
            <NumberInput
              class="player-param-input"
              :model-value="get(player, param.param)"
              :is-error="checkIsError(get(player, param.param), param.param)"
              :error-message="getErrorMessage(param.param)"
              :has-arrows="true"
              @update:modelValue="onPlayerPropChange($event, player, param)"
              @onKeyUp="onPlayerPropIncrease(player, param)"
              @onKeyDown="onPlayerPropDecrease(player, param)"
            />
          </div>
        </div>
        <MarketsPerPlayerSingle
          class="markets-per-player-container"
          :player-market-info="player"
          :team-label="teamLabel"
          :sport-id="sportId"
          :lineup="totalLineup"
        />
      </div>
    </div>
    <div
      v-if="!data.length"
      class="no-data-message"
    >
      No data for current selection
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { split, get } from 'lodash';
import {
  footballParameters, basketballParameters, hockeyParameters,
} from '@/services/helpers/trading-ui-mapper';
import sportIds from '@/services/helpers/sports';
import NumberInput from '@/components/common/NumberInput';
import Icon from '@/components/common/Icon';
import { getLowerLimit, getUpperLimit, getPlayerMinutesRemainingValue } from '@/services/helpers/player-setup-mapper';
import MarketsPerPlayerSingle from './MarketsPerPlayerSingle';
import PlayerMinutesRemainingInfo from '@/components/player-setup/common/PlayerMinutesRemainingInfo';
import Tooltip from '@/components/common/Tooltip';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
} = sportIds;

export default {
  emits: ['onPropChange', 'onPropIncrease', 'onPropDecrease'],
  components: {
    NumberInput,
    Icon,
    MarketsPerPlayerSingle,
    PlayerMinutesRemainingInfo,
    Tooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    event: {
      type: Object,
      required: true,
    },
    teamLabel: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const sportId = computed(() => props.event?.sportId);
    const totalLineup = computed(() => [
      ...(props.event?.homeLineup || []),
      ...(props.event?.awayLineup || []),
    ]);

    const playerParamsBySport = computed(() => {
      switch (sportId.value) {
      case BASKETBALL_ID:
        return basketballParameters();
      case FOOTBALL_ID:
        return footballParameters();
      case HOCKEY_ID:
        return hockeyParameters();
      default:
        return {};
      }
    });

    const showMinutesRemainingInfo = computed(() => sportId.value === BASKETBALL_ID);
    const pricingProbabilities = computed(() => props.event?.probabilities);
    const playerMinutesRemainingValue = (playerData) => {
      if (!showMinutesRemainingInfo.value) return 0;
      return getPlayerMinutesRemainingValue({
        probabilities: pricingProbabilities.value,
        playerIndex: playerData.playerIndex,
        teamSide: playerData.side,
      });
    };

    const formatPlayerName = (name) => {
      const formatedName = split(name, ',');
      return `${formatedName[1]} ${formatedName[0]}`;
    };

    const checkIsError = (newVal, param) => newVal < getLowerLimit([param]) || newVal > getUpperLimit([param]);

    const onPlayerPropChange = (newVal, player, param) => {
      emit('onPropChange', {
        playerId: player.playerId,
        teamCode: player.teamCode,
        param: param.param,
        value: newVal,
      });
    };

    const onPlayerPropIncrease = (player, param) => {
      emit('onPropIncrease', {
        playerId: player.playerId,
        teamCode: player.teamCode,
        param: param.param,
      });
    };
    const onPlayerPropDecrease = (player, param) => {
      emit('onPropDecrease', {
        playerId: player.playerId,
        teamCode: player.teamCode,
        param: param.param,
      });
    };

    const getErrorMessage = (param) => `Value must be between ${getLowerLimit([param])} and ${getUpperLimit([param])}`;

    return {
      sportId,
      totalLineup,
      playerParamsBySport,
      showMinutesRemainingInfo,
      formatPlayerName,
      onPlayerPropChange,
      onPlayerPropIncrease,
      onPlayerPropDecrease,
      checkIsError,
      getErrorMessage,
      playerMinutesRemainingValue,
      get,
    };
  },
};
</script>

<style lang="scss">
.player-props-root {
    height: calc(100% - 64px);
    overflow-y: auto;
    padding: 16px 0;
    .single-player-props-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .single-player-props {
        .player-props-header {
           .player-name {
              display: flex;
              align-items: center;
              gap: 8px;
              color: $black;
              font-size: 14px;
              font-weight: 600;

              .tooltip {
                .tooltip-element {
                  width: 14px;
                  height: 14px;
                }
                .tooltip-text {
                  font-weight: normal;
                }
              }
              .icon {
                &.incorect-projected-minutes-icon {
                  width: 16px;
                  height: 16px;
                  svg {
                    path {
                      fill: $error500;
                    }
                  }
                }
              }

           }
           .team-name {
            color: $gray800;
            font-size: 12px;
           }
        }
        .minutes-remaining-info-root {
          padding: 8px 0;
        }
        .player-params-container {
            padding: 8px 0 16px 0;
            display: flex;
            gap: 32px;
            flex-wrap: wrap;

            .player-params-single {
                width: calc(12.5% - 12px);
                .params-single-header {
                  font-size: 10px;
                  font-weight: 600;
                  text-transform: uppercase;
                  color: $gray700;
                  margin-bottom: 4px;
                  width: 100%;
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .player-param-input {
                  width: 70px;
                  height: auto;
                }
            }
        }
      }
    }
    .no-data-message {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
}

</style>
